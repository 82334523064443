import concurrentEditing from '../external/common/services/concurrent-editing';
import { EditorAppContext } from '../types/editor-app-context.type';
import { addBlocksWidget } from './add-blocks-widget';

export const installPostPage = async (context: EditorAppContext) => {
  console.log('[Blog] Installing Blocks Post Page...');

  await concurrentEditing.withApproval(async () => {
    try {
      const postPageRef = await addBlocksWidget({
        context,
        widgetName: 'postPage',
        y: 0,
      });

      console.log('[Blog] postPageRef', postPageRef);
      console.log('[Blog] Installing Blocks Post Page... Done!');
    } catch (err) {
      console.error('[Blog] Installing Blocks Post Page... Failed!', err);
      throw err;
    }
  });
};
