import {
  ElementsPanelParams,
  openElementsPanel,
} from '../../../blocks/common/elements-panel';
import { EditorAppContext } from '../../../types/editor-app-context.type';
import { POST_HEADER_SELECTORS } from '../constants';

export const openHeaderElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) =>
  openElementsPanel(context, {
    widgetRef,
    categoriesData: [
      { id: CATEGORY_IDS.mainElements, title: 'Main Elements' },
      { id: CATEGORY_IDS.metadata, title: 'Metadata' },
      { id: CATEGORY_IDS.counters, title: 'Counters' },
      { id: CATEGORY_IDS.decorative, title: 'Decorative' },
    ],
    // prettier-ignore
    elementsData: [
      { label: COMPS_DATA.categories.label,     identifier: { role: COMPS_DATA.categories.role },     categoryId: CATEGORY_IDS.mainElements, index: 0, },
      { label: COMPS_DATA.title.label,          identifier: { role: COMPS_DATA.title.role },          categoryId: CATEGORY_IDS.mainElements, index: 1, },
      { label: COMPS_DATA.rating.label,         identifier: { role: COMPS_DATA.rating.role },         categoryId: CATEGORY_IDS.mainElements, index: 2, },
      { label: COMPS_DATA.tags.label,           identifier: { role: COMPS_DATA.tags.role },           categoryId: CATEGORY_IDS.mainElements, index: 3, },
      { label: COMPS_DATA.writer.label,         identifier: { role: COMPS_DATA.writer.role },         categoryId: CATEGORY_IDS.mainElements, index: 4, },
      // Metadata section
      { label: COMPS_DATA.metadataWriter.label, identifier: { role: COMPS_DATA.metadataWriter.role }, categoryId: CATEGORY_IDS.metadata, index: 0, },
      { label: COMPS_DATA.publishDate.label,    identifier: { role: COMPS_DATA.publishDate.role },    categoryId: CATEGORY_IDS.metadata, index: 1, },
      { label: COMPS_DATA.readingTime.label,    identifier: { role: COMPS_DATA.readingTime.role },    categoryId: CATEGORY_IDS.metadata, index: 2, },
      { label: COMPS_DATA.lastUpdated.label,    identifier: { role: COMPS_DATA.lastUpdated.role },    categoryId: CATEGORY_IDS.metadata, index: 3, },
      // Counters section
      { label: COMPS_DATA.likes.label,          identifier: { role: COMPS_DATA.likes.role },          categoryId: CATEGORY_IDS.counters, index: 0, },
      { label: COMPS_DATA.views.label,          identifier: { role: COMPS_DATA.views.role },          categoryId: CATEGORY_IDS.counters, index: 1, },
      { label: COMPS_DATA.comments.label,       identifier: { role: COMPS_DATA.comments.role },       categoryId: CATEGORY_IDS.counters, index: 2, },
      // Decorative section
      { label: COMPS_DATA.divider.label,        identifier: { role: COMPS_DATA.divider.role },        categoryId: CATEGORY_IDS.decorative, index: 0,
      },
    ],
  });

const COMPS_DATA = {
  categories: { role: POST_HEADER_SELECTORS.CATEGORIES, label: 'Categories' },
  title: { role: POST_HEADER_SELECTORS.TITLE, label: 'Title' },
  rating: { role: POST_HEADER_SELECTORS.RATINGS, label: 'Rating' },
  tags: { role: POST_HEADER_SELECTORS.TAGS, label: 'Tags' },
  writer: { role: POST_HEADER_SELECTORS.METADATA, label: 'Writer' },

  // metadata
  metadataWriter: { role: POST_HEADER_SELECTORS.WRITER, label: 'Writer' },
  publishDate: {
    role: POST_HEADER_SELECTORS.PUBLISH_DATE,
    label: 'Publish date',
  },
  readingTime: {
    role: POST_HEADER_SELECTORS.READING_TIME,
    label: 'Reading time',
  },
  lastUpdated: {
    role: POST_HEADER_SELECTORS.LAST_UPDATED,
    label: 'Last updated',
  },

  // counters
  likes: { role: POST_HEADER_SELECTORS.LIKES, label: 'Likes' },
  views: { role: POST_HEADER_SELECTORS.VIEWS, label: 'Views' },
  comments: { role: POST_HEADER_SELECTORS.COMMENTS, label: 'Comments' },

  // decorative
  divider: { role: POST_HEADER_SELECTORS.DIVIDER, label: 'Divider' },
};

const CATEGORY_IDS = {
  mainElements: 'mainElements',
  metadata: 'metadata',
  counters: 'counters',
  decorative: 'decorative',
} as const;
